@import '../variables.scss';

.card-template {
  min-height: 100%;

  .card-body {
    display: flex;
    flex-direction: column;
    gap: $gap-small;

    .block {
      > .header {
        position: relative;
        transition: $transit-norm;
        height: 2048px;

        .edit-wrap {
          opacity: 0;
          visibility: hidden;
          transition: $transit-norm;
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          gap: $gap-medium;

          &.show {
            opacity: 1;
            visibility: visible;
          }
        }
      }
    }

    > .children-wrap {
      display: flex;
      flex-grow: 1;
      overflow: hidden;
      position: relative;

      > .cover {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.5);
        opacity: 0;
        visibility: hidden;

        &.show {
          opacity: 1;
          visibility: visible;
        }
      }
    }
  }
}
