@import './variables.scss';
@import './fonts.scss';

* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html,
body {
  max-width: 100vw;
  overflow-x: hidden;
}

body {
  background-color: $gray-100;
}

body,
button,
input,
textarea,
p {
  font-family: 'Roboto', Arial, sans-serif;
  letter-spacing: 0.025em;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  text-transform: uppercase;
  letter-spacing: 0.05em;
  line-height: 1.33333;
}

h1 {
  font-size: 60px;
  font-weight: 400;
}

h2 {
  font-size: 48px;
  font-weight: 400;
}

h3 {
  font-size: 33px;
  font-weight: 400;
}

h4 {
  font-size: 24px;
  font-weight: 400;
}

h5 {
  font-size: 18px;
  font-weight: 500;
}

h6 {
  font-size: 16px;
  font-weight: 500;
}

a,
p {
  font-size: 14px;
  line-height: 18px;
  letter-spacing: 0.05em;
  font-weight: 400;
  color: $gray-800;
}

p {
  text-transform: none;
}

a {
  text-transform: uppercase;
  text-decoration: none;
  transition: $transit-norm;

  &:hover {
    color: $primary;
  }

  &.disabled {
    cursor: unset;

    &:hover {
      color: unset;
    }
  }
}

hr {
  margin: 20px 0;
  border-color: $gray-1;
}

input,
textarea {
  padding: 16px 19px;
  font-size: 14px;
  font-weight: 400;
  border-radius: 3px;
  border: 1px solid $gray-1;
  color: $gray-2;
  transition: $transit-norm;

  &:focus {
    outline: none;
    border-color: $primary;
  }

  &::placeholder {
    color: $gray-500;
    opacity: 1;
  }
}

input {
  &.small {
    padding: 4px 14px;
  }
}

textarea {
  resize: none;
}

button,
.button {
  display: block;
  background-color: $primary;
  border: none;
  transition: $transit-norm;
  cursor: pointer;
  border-radius: 4px;
  min-height: 50px;
  color: white;
  font-size: 14px;
  font-weight: 700;
  text-transform: uppercase;
  padding: 14px 32px;

  &:hover {
    background-color: $secondary;
  }

  &.small {
    height: fit-content;
    min-height: unset;
    font-weight: 600;
    padding: 6px 14px;
    font-size: 12px;
    line-height: 1;
  }

  &.bw {
    background-color: white;
    color: $gray-800;
    border: 1px solid $gray-3;

    &:hover {
      color: white;
      background-color: $gray-2;
      border-color: $gray-2;
    }
  }

  &.white,
  &.transparent {
    background-color: transparent;
    border: 1px solid rgba(0, 0, 0, 0);

    &:hover {
      border: 1px solid $gray-3;
    }
  }

  &.white {
    color: white;
  }

  &.transparent {
    color: $gray-800;
  }

  &.danger {
    color: white;
    background-color: $danger;

    &:hover {
      color: white;
      background-color: $gray-2;
      border-color: $gray-2;
    }
  }
}

ul {
  &.tab-header {
    list-style-type: none;
    color: $gray-500;
    font-weight: 500;
    text-transform: uppercase;
  }
}

.fa-cross::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f00d';
}

.fa-pen::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f040';
}

.fa-left-arrow::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f060';
}

.fa-up-arrow::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f062';
}

.fa-down-arrow::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f063';
}

.fa-calendar::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f133';
}

.fa-check::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f00c';
}

.fa-upload::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f093';
}

.fa-chevron-up::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f077';
}

.fa-chevron-down::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f078';
}

.fa-user::before {
  font-weight: 400;
  font-family: 'FontAwesome';
  font-size: 16px;
  content: '\f007';
}

.bg-image {
  background-position: center;
  background-size: 100%;
  background-repeat: no-repeat;
  transition: $transit-norm;

  &:hover {
    background-size: 103%;
  }
}

.wh-256 {
  width: 256px;
  height: 256px;
}

.wh-128 {
  width: 128px;
  height: 128px;
}

.wh-96 {
  width: 96px;
  height: 96px;
}

.wh-64 {
  width: 64px;
  height: 64px;
}

.wh-32 {
  width: 32px;
  height: 32px;
}

.font-16 {
  font-size: 16px;
  line-height: 22px;
}

.font-medium {
  font-weight: 500;
}

.text-center {
  text-align: center;
}

.upper {
  text-transform: uppercase;
}

.primary {
  color: $primary;
}

.secondary {
  color: $secondary;
}

.success {
  color: $success;
}

.danger {
  color: $danger;
}

.gray-500 {
  color: $gray-500;
}

.pointer {
  cursor: pointer;
}

.container {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: $container-max-widths-xxl;
  padding: 0 8px;
  margin: 0 auto;
}

.block {
  width: 100%;
  padding: 30px 35px;
  background: white;
  border: 1px solid $gray-1;
  transition: $transit-norm;

  &:hover {
    box-shadow: $shadow-norm;
  }
}

.modal {
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 40px;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
  z-index: $z-modal;
  background-color: rgba(0, 0, 0, 0.5);
  visibility: hidden;
  opacity: 0;
  transition: $transit-norm;

  &.show {
    opacity: 1;
    visibility: visible;
  }
}

@media (max-width: $breakpoint-xxl) {
  .container {
    max-width: $container-max-widths-xl;
  }
}

@media (max-width: $breakpoint-xl) {
  .container {
    max-width: $container-max-widths-lg;
  }
}

@media (max-width: $breakpoint-lg) {
  .container {
    max-width: $container-max-widths-md;
  }
}

@media (max-width: $breakpoint-md) {
  .container {
    max-width: $container-max-widths-sm;
  }
}

@media (max-width: $breakpoint-sm) {
  .container {
    max-width: 100%;
  }

  .block {
    padding: 16px 12px;
  }

  .modal {
    padding: 20px;
  }
}
