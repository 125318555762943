@import '../variables.scss';

.tournament-card {
  .tournament-card-tabs {
    .teams-list-wrap {
      display: flex;
      flex-direction: column;
    }
  }

  .fa-calendar::before {
    color: $gray-500;
  }
}
