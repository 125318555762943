@import '../variables.scss';

.game-block-score-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: $gap-xsmall;

  .input-wrap {
    display: flex;
    gap: $gap-xsmall;

    .team1,
    .team2 {
      display: flex;
      flex-direction: column;
      gap: $gap-xsmall;
      align-items: center;
    }
  }

  > .error {
    color: $danger;
    max-height: 0px;
    opacity: 0;
    transition: $transit-norm;

    &.show {
      opacity: 1;
      max-height: 24px;
    }
  }
}
