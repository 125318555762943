@import '../variables.scss';

.game-list {
  display: flex;
  flex-direction: column;
  gap: $gap-medium;

  > .controls {
    display: flex;
    flex-direction: column;
    gap: $gap-small;

    .row {
      display: flex;
      gap: $gap-small;
      align-items: center;

      .referees-check-box {
        padding-left: 0;
        padding-right: 0;
      }

      .save-to-pdf-btn {
        display: flex;
        align-items: center;
        gap: $gap-xxsmall;
        padding-top: 8px;
        padding-bottom: 8px;

        .pdf-icon {
          background-image: url('../assets/images/pdf.png');
        }
      }
    }
  }

  > .day,
  > .referee {
    display: flex;
    flex-direction: column;
    gap: $gap-small;

    .day-table,
    .field-table {
      display: flex;
      flex-direction: column;
      gap: $gap-xxsmall;
    }

    .total-day,
    .total-referee,
    .total-field {
      text-align: right;
    }
  }

  .game-list-pdf-wrap {
    position: absolute;
    left: -9999px;
    top: 0;
  }
}
