@import '../variables.scss';

.game-block {
  position: absolute;
  left: 0;
  width: 100%;
  cursor: grab;
  padding: 1px 0;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  &.dragging {
    opacity: 0.5;
    cursor: grabbing;
  }

  &:hover {
    .content-wrap {
      .edit-btn,
      .score-btn {
        opacity: 1;
      }
    }
  }

  > .content-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    row-gap: $gap-xxxsmall;
    border: 2px solid;

    .resize-handle {
      position: absolute;
      height: 6px;
      width: 100%;
      cursor: ns-resize;

      &.top {
        top: 0;
      }

      &.bottom {
        bottom: 0;
      }
    }

    .round-game {
      text-transform: unset;
    }

    .score-btn {
      position: absolute;
      top: 8px;
      left: 0;
      opacity: 0;
    }

    .edit-btn {
      position: absolute;
      top: 8px;
      right: 0;
      opacity: 0;
    }

    .referees {
      text-align: center;
    }
  }
}
