@import '../variables.scss';

.multi-input {
  position: relative;
  width: 480px;
  max-width: 100%;

  &.small {
    .input-field {
      min-height: 26px;
      padding: 3px 14px;

      .value {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .items {
      padding: 6px 0;
    }
  }

  .input-field {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 13px 19px;
    background-color: white;
    border: 1px solid $gray-1;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    min-height: 50px;
    transition: $transit-norm;

    &.error {
      border-color: $danger;
    }

    .value {
      font-size: 14px;
      line-height: 1.5;

      &.placeholder {
        color: $gray-500;
      }
    }

    .error-msg {
      position: absolute;
      top: 2px;
      right: 4px;
      opacity: 0;
      visibility: hidden;
      transition: $transit-norm;
      font-size: 12px;
      color: $danger;

      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .items {
    padding: 12px 0;
    margin: 0;
    border: 1px solid $gray-1;
    border-radius: 4px;
    border-top: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    transition: $transit-norm;
    visibility: hidden;
    opacity: 0;
    overflow: scroll;
    max-height: 340px;
    display: flex;
    flex-direction: column;
    z-index: -1;

    &.open {
      visibility: visible;
      opacity: 1;
    }

    &.z-index {
      z-index: $z-modal;
    }

    .select-all {
      .label {
        font-weight: 500;
      }
    }

    .add-wrap {
      margin: 0 19px;
      min-height: 42px;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      &.small {
        margin: 0 8px;
        min-height: 30px;
      }

      .add-btn {
        position: absolute;
        transition: $transit-norm;
        opacity: 1;
        visibility: visible;

        &.add-mode {
          opacity: 0;
          visibility: hidden;
        }
      }

      .add-edit {
        position: absolute;
      }
    }
  }
}
