@import '../variables.scss';

.field {
  min-width: 280px;

  > .header {
    background-color: $gray-2;
    color: whitesmoke;
    padding: 8px 14px;
    line-height: 100%;
  }

  .body {
    position: relative;
    border: 1px solid $gray-1;
    background-color: $gray-7;

    .divider {
      position: absolute;
      width: 100%;
      left: 0;
      border-top: 1px dashed $gray-500;
    }

    .drop-zone {
      width: 100%;
      height: 100%;
    }
  }
}
