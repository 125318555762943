@import '../variables.scss';

.image-btns {
  display: flex;
  flex-direction: column;
  gap: $gap-xsmall;
  align-items: center;
  position: relative;

  .bg-image {
    &.clickable {
      cursor: pointer;
    }
  }

  .upload-icon-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: $transit-norm;

    &.upload {
      cursor: pointer;

      &:hover {
        transform: scale(103%);
      }
    }

    .fa-upload::before {
      font-size: 60px;
    }
  }

  .progress-wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: $gap-xsmall;

    .progress-line {
      width: 100%;
      border: 1px solid $gray-1;
      height: 24px;

      .progress {
        background-color: $primary;
        height: 100%;
      }
    }
  }

  .btns {
    display: flex;
    column-gap: $gap-xsmall;
    row-gap: $gap-xsmall;
    flex-wrap: wrap;
    justify-content: center;
    width: 100%;

    .upload-wrap {
      position: relative;

      .select-inline-type {
        position: absolute;
        left: 50%;
        top: 50%;
        transform: translate(-50%, -50%);
        width: 240px;
      }
    }
  }

  .image-title {
    &.no-title {
      visibility: hidden;
    }
  }

  .error {
    color: $danger;
    text-align: center;
    overflow: hidden;
    max-height: 0;
    transition: $transit-norm;

    &.show {
      max-height: 20px;
    }
  }

  .file-upload-input {
    display: none;
  }
}
