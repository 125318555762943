@import '../variables.scss';

.select {
  position: relative;

  &.small {
    .selected-option {
      padding: 3px 14px;

      .value {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
      }
    }

    .options {
      li {
        font-size: 12px;
        font-weight: 600;
        text-transform: uppercase;
        padding: 6px 14px;
      }
    }
  }

  .selected-option {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 17px 19px;
    background-color: white;
    border: 1px solid $gray-1;
    border-radius: 4px;
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: $transit-norm;

    &.error {
      border-color: $danger;
    }

    .value {
      font-size: 14px;
      line-height: 1;

      &.placeholder {
        color: $gray-500;
      }
    }

    .error-msg {
      position: absolute;
      top: 2px;
      right: 4px;
      opacity: 0;
      visibility: hidden;
      transition: $transit-norm;
      font-size: 12px;
      color: $danger;

      &.visible {
        visibility: visible;
        opacity: 1;
      }
    }
  }

  .options {
    list-style: none;
    padding: 0;
    margin: 0;
    border: 1px solid $gray-1;
    border-radius: 4px;
    border-top: none;
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: white;
    transition: $transit-norm;
    visibility: hidden;
    opacity: 0;
    overflow: scroll;
    z-index: $z-modal;
    max-height: 280px;

    &.open {
      visibility: visible;
      opacity: 1;
    }

    li {
      cursor: pointer;
      color: $gray-800;
      font-size: 14px;
      line-height: 1;
      padding: 12px 19px;

      &:hover {
        color: white;
        background-color: $primary;
      }
    }
  }
}
