@import '../variables.scss';

@keyframes show-up {
  from {
    transform: scale(0.8);
  }
  to {
    transform: scale(1);
  }
}

.block-modal-edit-template {
  position: absolute;
  z-index: $z-modal;
  min-width: 360px;
  cursor: auto;

  visibility: hidden;
  opacity: 0;
  transition: $transit-norm;

  &.show {
    visibility: visible;
    opacity: 1;

    .white-back {
      animation: show-up $transit-norm;
    }
  }

  .cover {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
  }

  .white-back {
    position: relative;
    background-color: white;

    .header {
      background-color: $gray-2;
      color: whitesmoke;
      padding: 8px 14px;
      line-height: 100%;

      .close-btn {
        position: absolute;
        top: $gap-xxsmall;
        right: $gap-xxsmall;
      }
    }

    .content-wrap {
      position: relative;
      display: flex;
      flex-direction: column;
      align-items: center;
      row-gap: $gap-xsmall;

      .round-game {
        text-transform: none;
        cursor: pointer;
      }

      background: white;
    }
  }
}
