@import '../variables.scss';

.game-list-table {
  .header {
    display: flex;
    gap: $gap-xsmall;

    h6 {
      padding: 8px;
      background-color: $gray-2;
      color: whitesmoke;
    }
  }

  .lines {
    border-left: 1px solid $gray-500;
    border-right: 1px solid $gray-500;

    .line {
      display: flex;
      gap: $gap-xsmall;

      p {
        padding: 8px;
        font-size: 16px;
        border-bottom: 1px solid $gray-500;
      }
    }
  }

  .col-1,
  .col-2,
  .col-3,
  .col-4,
  .col-5,
  .col-6 {
    flex-basis: 0;
    flex-shrink: 0;
  }

  .col-1 {
    flex-grow: 2;
  }

  .col-2 {
    flex-grow: 2;
  }

  .col-3 {
    flex-grow: 2;
  }

  .col-4 {
    flex-grow: 4;
  }

  .col-5 {
    flex-grow: 2;
  }

  .col-6 {
    flex-grow: 1;
  }
}
