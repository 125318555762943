@import '../variables.scss';

.time-line {
  list-style: none;
  color: $gray-500;
  font-size: 12px;
  padding-top: 32px;

  li {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }

  .minutes-00 {
    color: black;
  }
}
