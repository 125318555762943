@import '../variables.scss';

.multi-input-item-edit {
  width: 100%;
  display: flex;
  gap: $gap-xsmall;
  opacity: 0;
  visibility: hidden;
  transition: $transit-norm;
  opacity: 0;
  visibility: hidden;

  &.show {
    opacity: 1;
    visibility: visible;
  }

  .input-new-item {
    flex-grow: 1;

    input {
      padding-left: 10px;
    }
  }
}
