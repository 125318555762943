@import '../variables.scss';

.game-list-pdf {
  width: 794px;
  min-height: 1123px;
  padding: 20px;
  background-color: white;
  color: #000;

  display: flex;
  flex-direction: column;
  gap: $gap-small;

  .title {
    text-align: center;
  }

  .color-legend {
    row-gap: $gap-xsmall;
  }

  .day,
  .referee {
    display: flex;
    flex-direction: column;

    .day-table,
    .field-table {
      display: flex;
      flex-direction: column;
      gap: $gap-xxsmall;
      page-break-inside: avoid;
      break-inside: avoid-page;
      padding-top: $gap-small;
    }

    .total-day,
    .total-referee,
    .total-field {
      text-align: right;
    }
  }

  .game-list-table {
    .header h6 {
      font-size: 14px;
      padding: 6px 4px;
    }

    .lines .line p {
      font-size: 12px;
      padding: 2px 4px;
    }
  }
}

@media print {
  .new-page {
    page-break-before: always;
    break-before: page;
    padding-top: 20px;
  }
}
