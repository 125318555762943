@import '../variables.scss';

@keyframes accent {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
  }
}

.brackets-node {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: $gap-xxxsmall;
  width: 260px;
  height: 120px;
  border: 2px solid $gray-500;
  padding: $gap-xsmall;
  background-color: white;
  transition: $transit-norm;

  &.highlight {
    animation: accent 1s 2;
    border: 4px solid $primary;

    .placement {
      color: $primary;
    }
  }

  &.set {
    &:hover {
      border-color: $secondary;
    }
  }

  .referees {
    text-align: center;
  }

  .placement {
    position: absolute;
    left: $gap-xsmall;
    top: $gap-xsmall;
    color: $gray-500;
    font-weight: 600;
  }
}
