@import '../variables.scss';

.multi-input-item {
  display: flex;
  align-items: center;
  gap: $gap-xsmall;
  padding: 9px 8px;

  .item-btns-wrap {
    height: 26px;
    position: relative;
    flex-grow: 1;

    .no-edit {
      width: 100%;
      padding: 0 12px;
      position: absolute;
      top: 0;
      left: 0;
      display: flex;
      align-items: center;
      gap: $gap-xsmall;
      transition: $transit-norm;
      opacity: 1;
      visibility: visible;

      .up-down-btns {
        display: flex;

        button {
          padding: 6px;
        }
      }

      &.edit-mode {
        opacity: 0;
        visibility: hidden;
      }

      .item {
        flex-grow: 1;
      }

      .btns-wrap {
        display: flex;

        button {
          opacity: 0;
        }
      }

      &:hover {
        .btns-wrap {
          button {
            opacity: 1;
          }
        }
      }
    }

    .edit {
      position: absolute;
      top: 0;
      left: 0;
    }
  }

  &.small {
    padding: 6px 14px;

    .item-btns-wrap {
      .no-edit {
        .item {
          font-size: 12px;
          font-weight: 600;
          text-transform: uppercase;
        }
      }
    }
  }
}
