@import '../variables.scss';

.color-legend {
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  column-gap: $gap-medium;
  row-gap: $gap-small;

  li {
    display: flex;
    gap: $gap-small;
    align-items: center;

    .color-box {
      width: 32px;
      height: 32px;
      flex-shrink: 0;
    }

    .division-name {
      white-space: pre-line;
    }
  }
}
