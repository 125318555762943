@import '../variables.scss';

.referees-list {
  position: relative;
  display: flex;
  flex-direction: column;
  row-gap: $gap-small;
  overflow-y: hidden;
  overflow-x: hidden;

  .controls {
    display: flex;
    justify-content: space-between;
    gap: $gap-small;
    flex-wrap: wrap;
    border-bottom: 1px solid $gray-200;
    padding-bottom: $gap-small;
  }

  .tab-header {
    display: flex;
    gap: $gap-small;
  }

  .tab-body {
    display: flex;
    flex-direction: column;
    gap: $gap-small;

    .referee {
      display: flex;
      gap: $gap-small;
      align-items: center;

      p {
        transition: $transit-norm;
      }

      &:hover {
        p {
          color: unset;
        }
      }
    }
  }

  .col-2,
  .col-3,
  .col-4,
  .col-34 {
    flex-basis: 0;
    flex-grow: 1;
    flex-shrink: 0;
  }

  .col-1 {
    width: 64px;
    flex-shrink: 0;
  }

  .col-34 {
    display: none;
  }
}

@media (max-width: $breakpoint-md) {
  .referees-list {
    .col-1 {
      width: 32px;
    }

    .col-3,
    .col-4 {
      display: none;
    }

    .col-34 {
      display: block;
    }
  }
}
