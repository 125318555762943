@import '../variables.scss';

.scheduler {
  display: flex;
  flex-direction: column;
  gap: $gap-small;
  width: fit-content;

  .control-wrap {
    padding-left: 61px;
    display: flex;
    gap: $gap-small;
    flex-direction: column;

    .filter-wrap {
      display: flex;
      gap: $gap-xsmall;
      align-items: flex-start;

      > * {
        min-width: 280px;
      }
    }
  }

  .timeline-fields-wrap {
    display: flex;
    gap: $gap-xsmall;
  }

  .brackets-popup {
    .get-click-outside {
      width: 100%;
      height: 100%;
      max-width: 1600px;
      max-height: 900px;

      .block {
        height: 100%;
        position: relative;

        .close-btn {
          position: absolute;
          top: $gap-xsmall;
          right: $gap-xsmall;
        }
      }
    }
  }
}
